// import "@lsg/components";

// $lsg-color-col-background-dark: "#ff0000" !default;


// %scope {
//   @import "@lsg/components/dist/styles";

//   $lsg-color-col-background-dark: "#ff0000" !global;
//   $brand-primary: invert($brand-warning) !global;
// }

.lsgs--header-container-logo {
  max-width: 253px;
  height: auto!important;
  transform: unset!important;
}
.lsgs--logo__fit .lsgs--logo-svg {
  height:auto;
}

.lsgs--logo.lsgs--logo__fit  .lsgs--action {
  height: auto;
}

.lsgs--logo.lsgs--logo__fit {
  display: flex;
  align-items: center;
}

.lsgs--header-container-wrapper .lsgs--header-container-row:first-child {
  border-bottom: 1px solid #fff;
}

.contact-section {
  padding-bottom: 100px!important;

  @media screen and (max-width: 768px){
    padding-bottom:70px;
  }
}

.contact-box-wrapper {
  position: relative;
}

.contact-box {
  background: #fff;
  box-shadow: 5px 5px 10px #00000029;
  padding:50px;
  max-width: 690px;
  width: 45%;
  position: absolute;
  left:100px;
  bottom: -20%;

  @media screen and (max-width: 768px){
    position: static;
    width: 100%;
    padding:20px;
    box-sizing: border-box;
    h3 {
      font-size: 17px !important;
    }
  }
}

.contrast-wrapper {
  .contrast-bt {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    font-size: 0!important;
    display: block;
    cursor: pointer;


    svg {
      transition: all 0.3s;
      fill: #dce6e6;
      font-size: 0!important;
      &:hover {
        fill:#ffd700;

      }
    }

    &.active {
      svg {
        fill:#ffd700;
      }
    }
  }
  position: relative;

  .contrast-buttons {
    position: absolute;
    transform: translate(-15px,35%);
    background-color: #002e3c;
    padding:10px;
    .contrast-buttons-inner {
      display: flex;
      gap:2px;
      .contrast-select-bt {
        width: 15px;
        height: 15px;
        font-size: 10px!important;
        font-weight: bold;
        border:1px solid #fff;
        display: flex;
        color: #fff!important;
        justify-content: center;
        align-items: center;
        font-family: Gotham, sans-serif;
        cursor:pointer;
        background-color: transparent;
        transition: all 0.3s;
        text-transform: uppercase;

        &.black-white {
          background-color: #000000!important;
          color:#fff!important;
        }

        &.black-yellow {
          background-color: #000000!important;
          color:#ffd700!important;
        }

        &.yellow-black {
          background-color: #ffd700!important;
          color:#000000!important;
        }
      }
    }
  }
}



.font-size-changer {
  margin-right: 15px;
  .font-size-up, .font-size-down {
    border-color: #dce6e6;
    color:#dce6e6;
    font-family: Gotham, sans-serif;
    width: 20px;
    height: 20px!important;
    line-height: 20px!important;

    span {
      line-height: 20px!important;
    }

    &:hover {
      color: #ffd700;
      border-color: #ffd700;
    }
  }

  .font-size-up {
    font-size: 16px!important;
    span {
      font-size: 16px!important;
    }
  }

  .font-size-down {
    font-size: 12px!important;
    span {
      font-size: 12px!important;
    }
  }
}

.cookie-btn {
  border-radius: 32px!important;
  font-family:  Gotham, sans-serif;
  font-weight: 500;
  color: #002e3c!important;
  padding: 15px 20px!important;
  background-color: #ffd700!important;
}

.CookieConsent {
  p {
    font-family:  Gotham, sans-serif!important;
  }

  a {
    color: #fff;
    text-decoration: underline;
  }
}

.legal-notice p {
  max-width: 100%;
  font-size: 15px;
}

h2 {
  font-size: 32px!important;
  font-weight: 500!important;
  line-height: 39px!important;
}

.team-itm {
  margin-bottom: 30px;
  max-width: 80%;

  @media screen and (max-width: 768px){
    max-width: 100%;
    margin-bottom: 50px;

    img {
      margin-left:auto;
      margin-right: auto;
    }
  }

  .lsgs-MS4xNC4w--picture {

  }
}

.team-itm img {
  max-width: 86px;
  max-height: 88px;
  height: 100%;
  width: auto;
  margin-bottom: 30px;
}

.team-itm h4 {
  font-size: 22px!important;
}

.team-itm p {
  font-size: 15px!important;
}

.testimonial-itm {
  display: flex;
  flex-direction: row;
  margin-bottom: 90px;

  @media screen and (max-width: 768px){
    flex-direction: column;
  }
}

.testimonial-itm .tst-image {
  max-width: 220px;
  margin-right: 130px;

  img {
    border-radius: 50%;
  }

  @media screen and (max-width: 768px){
    margin-left: auto;
    margin-right: auto;
  }
}

p,li {
  /*font-size: 15px!important;
  line-height: 24px!important;*/
  text-align: justify;
}



ol {
  div {
    &:before {
      font-size: 15px;
    }
  }

  li {
    text-align: left;
  }
}

.elevated-section {
  margin-bottom: 0;
  position: relative;
  z-index: 1;
}

.elevated-section-theme {
  background: #fff!important;
  position: relative;
}

.elevated-section-theme:after {
  content: '';
  width:100%;
  height: 100%!important;
  background: #002e3c;
  position: absolute;
  left:0;
  top:0;
}

.elevated-row {
  justify-content: space-between;
}

@media screen and (max-width: 1600px) {
  .elevated-section-theme:after {
    height: 100%!important;
  }
}

@media screen and (max-width: 1445px) {
  .elevated-section-theme:after {
    height: 100%!important;
  }

  .elevated-image  {
    display: block;
    // transform: translateY(10%);
  }
}

@media screen and (max-width: 1399px) {
  .elevated-section-theme:after {
    height: 100%!important;
  }

  .elevated-image  {
    display: block;
    // transform: translateY(15%);
  }
}

@media screen and (max-width: 1220px) {
  .elevated-section-theme:after {
    padding-bottom: 30px;
  }

  .elevated-image  {
    display: block;
    transform: translateY(0);
  }
}


#cta-btn {
  /*margin-right: 32px;*/
  transform: translateX(-32px);
}

.lsgs-MS4xNC4w--header-container-group-right .lsgs-MS4xNC4w--icon-link-group {
  overflow-x: unset;
}

.lsgs-MS4xNC4w--header-container-group-right .lsgs-MS4xNC4w--button-inner {
  height: 50px!important;
}

.contact-wrapper {
  display: flex;
  width: 100%;
  flex-shrink: 0;
  flex-direction: row;
}

.contact-items {
  width: 100%;
}

.contact-header {
  display: flex;
  align-items: flex-start;
  max-width: 40%;
  width: 100%;
  padding-right: 0;
}

.contact-header h4 {
  /*margin-top: 20px;*/
  text-align: left;
}

.contact-header .lsgs-MS4xNC4w--contact-module-actionbuttons {
  display: none;
}

.contact-header .lsgs-MS4xNC4w--contact-module-toparea {
  width: 100%;
  max-width: 100%;
}

@media screen and (max-width: 768px) {
  .contact-wrapper {
    flex-direction: column;
  }

  .contact-header {
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    padding-right: 16px;
    text-align: center;
  }

  .contact-header h4 {
    max-width: 100%;
  }

  p {
    text-align: center;
  }
}

header .lsgs-MS4xNC4w--button  {
  margin-bottom: 10px!important;
}

.news-section a {
  text-decoration: none;
}

.lsgs-MS4xNC4w--portal-header-top-right .lsgs-MS4xNC4w--icon-link-group .lsgs-MS4xNC4w--icon-link {
  margin-right:15px;
}

.p-full-width p {
  max-width: 100%;
}

.lsgs-MS4xNC4w--action-button {
  width: auto;
}

.contact-items .second-line {
  padding-top:20px;
}

@media screen and (max-width: 1460px) {
  .contact-box h3 {
    font-size: 27px;
  }

}

.lsgs-MS4xNC4w--drawer-background {
  background-color: transparent !important;
}

.news-content {
  text-align: center;
  * {
    margin-left: auto;
    margin-right: auto;
  }

  &.page-content {
    p {
      max-width: 100%!important;
    }
  }
}

.home-stage {
  .lsgs-MS4xNC4w--picture-background {
    @media screen and (max-width: 768px){
      background-image: url('/public/header_HOME_768x1024_702-min.png')!important;
    }
  }
}

.career-stage {
  .lsgs-MS4xNC4w--picture-background {
    @media screen and (max-width: 768px) {
      background-image: url('/public/header_KARIERA_768x1024_702-min.png')!important;
    }
  }
}

.page-loader {
  min-height: 100vh!important;
}

.virtual-tour-iframe {
  min-height: 60vh;
}

.heading-margin {
    margin: 50px 0!important;
}

.about-section {
  .image-col {
    text-align: center;

    img {
      max-width: 70%;
      margin-left: auto;
      margin-right: auto;

      @media screen and (max-width: 768px){
        max-width: 100%;
      }
    }
  }
}

.lsgs-MS4xNC4w--drawer-content {
  .lsgs-MS4xNC4w--icon-link-label {
    /*display: none;*/
  }
}

@media screen and (max-width: 768px) {

  .lsgs-MS4xNC4w--header-container__mobile {
    .lsgs-MS4xNC4w--icon-link-label {
      display: none;
    }
  }

  h2,h3,h4 {
    text-align: center;
  }
}

.video-column {
  p {
    margin-bottom: 0!important;
  }
}

.wrapper {
  &.contrast-black-white {
    $bg-color: #000000;
    $f-color: #ffff;

    background-color: $bg-color!important;
    color:$f-color!important;

    .contrast-buttons {
      background-color: $bg-color;
    }

    .contact-box,.lsg-MS4xNC4w---theme__bg-brand, .elevated-section-theme {
      background-color: $bg-color!important;
    }

    .elevated-section-theme:after {
      background-color: $bg-color!important;
    }

    svg {
      fill: $f-color;
    }

    .lsgs-MS4xNC4w--footer {
      background-color: $bg-color!important;
    }

    .lsgs-MS4xNC4w--dwindle__primary {
      background-color: $bg-color;
      color: $f-color;
      border-color: $bg-color;
      box-shadow: 0 0 0 6px $bg-color, 0 0 0 0.5px $bg-color, 0 0 0 2px $bg-color, 0 0 0 1px $bg-color!important;
    }

    .lsgs-MS4xNC4w--dwindle__secondary {
      border-color: $f-color!important;
    }

    .lsg-MS4xNC4w---theme__bg-dark {
      background-color: $bg-color!important;
      color:$f-color!important;
    }
    header {
      background-color: $bg-color!important;
      color:$f-color!important;

      svg {
        fill:$f-color!important;
      }

      * {
        color:$f-color!important;
      }
    }

    * {
      color:$f-color!important;
    }

    .section {
      background-color: $bg-color!important;
      color:$f-color!important;
    }

    .lsgs-MS4xNC4w--icon__anthracite {
      fill: $bg-color;
    }

    .lsgs-MS4xNC4w--dwindle__secondary {
      border-color: $f-color!important;
    }
  }

  &.contrast-black-yellow {
    $bg-color: #000000;
    $f-color: #ffd700;

    background-color: $bg-color!important;
    color:$f-color!important;

    .contrast-buttons {
      background-color: $bg-color;
    }

    .contact-box,.lsg-MS4xNC4w---theme__bg-brand, .elevated-section-theme {
      background-color: $bg-color!important;
    }

    .elevated-section-theme:after {
      background-color: $bg-color!important;
    }

    svg {
      fill: $f-color;
    }

    .lsgs-MS4xNC4w--footer {
      background-color: $bg-color!important;
    }

    .lsgs-MS4xNC4w--dwindle__primary {
      background-color: $bg-color;
      color: $f-color;
      border-color: $bg-color;
      box-shadow: 0 0 0 6px $bg-color, 0 0 0 0.5px $bg-color, 0 0 0 2px $bg-color, 0 0 0 1px $bg-color!important;
    }

    .lsgs-MS4xNC4w--dwindle__secondary {
      border-color: $f-color!important;
    }

    .lsg-MS4xNC4w---theme__bg-dark {
      background-color: $bg-color!important;
      color:$f-color!important;
    }
    header {
      background-color: $bg-color!important;
      color:$f-color!important;

      svg {
        fill:$f-color!important;
      }

      * {
        color:$f-color!important;
      }
    }

    * {
      color:$f-color!important;
    }

    .section {
      background-color: $bg-color!important;
      color:$f-color!important;
    }

    .lsgs-MS4xNC4w--icon__anthracite {
      fill: $bg-color;
    }

    .lsgs-MS4xNC4w--dwindle__secondary {
      border-color: $f-color!important;
    }
  }

  &.contrast-yellow-black {
    $bg-color: #ffd700;
    $f-color: #000000;

    background-color: $bg-color!important;
    color:$f-color!important;

    .contrast-buttons {
      background-color: $bg-color;
    }

    .contact-box,.lsg-MS4xNC4w---theme__bg-brand, .elevated-section-theme {
      background-color: $bg-color!important;
    }

    .elevated-section-theme:after {
      background-color: $bg-color!important;
    }

    svg {
      fill: $f-color;
    }

    .lsgs-MS4xNC4w--footer {
      background-color: $bg-color!important;
    }

    .lsgs-MS4xNC4w--dwindle__primary {
      background-color: $bg-color;
      color: $f-color;
      border-color: $bg-color;
      box-shadow: 0 0 0 6px $bg-color, 0 0 0 0.5px $bg-color, 0 0 0 2px $bg-color, 0 0 0 1px $bg-color!important;
    }

    .lsgs-MS4xNC4w--dwindle__secondary {
      border-color: $f-color!important;
    }

    .lsg-MS4xNC4w---theme__bg-dark {
      background-color: $bg-color!important;
      color:$f-color!important;
    }
    header {
      background-color: $bg-color!important;
      color:$f-color!important;

      svg {
        fill:$f-color!important;
      }

      * {
        color:$f-color!important;
      }
    }

    * {
      color:$f-color!important;
    }

    .section {
      background-color: $bg-color!important;
      color:$f-color!important;
    }

    .lsgs-MS4xNC4w--icon__anthracite {
      fill: $bg-color;
    }

    .lsgs-MS4xNC4w--dwindle__secondary {
      border-color: $f-color!important;
    }
  }
}

.back-bt-row {
  margin-top:20px;
}

.typography {
  .left {
    text-align: left;
    margin-left: unset;
    iframe {
      margin-left: unset;
    }
  }

  .center {
    text-align: center;
    iframe {
      margin:0 auto;
    }
  }

  .right {
    text-align: right;
    margin-right: unset;
    iframe {
      margin-right: unset;
    }
  }
}


@media screen and (max-width: 768px){
  .lsgs-MS4xNC4w--icon-link-group__horizontal {
    align-items: center;

    .contrast-wrapper, .font-size-changer {
      margin-bottom: 8px;
    }
  }

  .embed {
    width: 100% !important;
    iframe {
      width: 100%!important;
      height: auto!important;
    }
  }
}

.gal-btn {
  cursor: pointer;
}

span[role=button] {
  cursor: pointer;
}


